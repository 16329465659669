//Scripts
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { IsProd } from '../utils/environment';
import { sha256 } from '../utils/businessUtils';
import ProcessingCircle from './../utils/processingCircle.js';

//Images
// import refreshImg from './../img/refresh.png';

function EmailRegistration() {
    // const bookMatch = useMatch('/book');
    const { t, i18n } = useTranslation();
    const language_code = i18n.language;
    const [email, setEmail] = useState('');
    const [statusLabel, setStatusLabel] = useState(null);
    const [processing, setProcessing] = useState(false);

    // const [captchaCode, setCode] = useState('');
    // const [captchaError, setCaptchaError] = useState(null);
    // const navigate = useNavigate();

    // const handleInputChange = (e) => {
    //     // Update the captcha code state and clear any existing captcha error
    //     setCode(e.target.value);
    //     setCaptchaError(null);
    //   };

    // const validateCode = (e) => {
    //     const input = e.target;
    //     if (input.validity.typeMismatch) {
    //         input.setCustomValidity(t("login-invalid-code"));
    //     } else {
    //         input.setCustomValidity(''); // Reset the validation message when the input is valid
    //     }
    // };

    const validateEmail = (e) => {
        const input = e.target;
        if (input.validity.typeMismatch) {
            input.setCustomValidity(t("login-invalid-email"));
        } else {
            input.setCustomValidity(''); // Reset the validation message when the input is valid
        }
    };

    const initUser = async (email) => {
        setProcessing(true);
        try {
            const response = await fetch('/api/account/init.php',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'User-Agent': navigator.userAgent,
                },
                body: JSON.stringify({
                    email,
                    language_code,
                    send_email: true,
                    return_url: window.location.href
                })
            });
            setProcessing(false);
            
            if (response.status !== 200)
            {
                setStatusLabel(t("login-no-server-connection"));
                return;
            }

            const data = await response.json();
            if (data.success) {
                setStatusLabel(data.statusLabel);
                const emailHash = await sha256(email.toLowerCase().trim());
                if (data.statusLabel === 'login-new-account' && IsProd()) {
                    // Trigger Google Ads Conversion Tracking
                    window.gtag('event', 'conversion', {
                        'send_to': 'AW-16578861916/yJqWCOvfwccZENy2teE9',
                        'value': 2.0, // Optional: Set a value for the conversion
                        'currency': 'CHF', // Adjust currency if needed
                        'user_data': {
                            'email_address': emailHash // Gehashte E-Mail-Adresse
                        },
                        'event_callback': 'callback'
                        // 'transaction_id': data.userId // Use a unique ID if available
                    });
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    
    const emailSubmit = (e) => {
        e.preventDefault();
        initUser(email);
    };

    return (
        <div className="login-and-registration">
            <h3>{t("login-no-password")}</h3>
            <form onSubmit={emailSubmit}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label>{t("login-email")}:</label>
                            </td>
                            <td>
                                <input type="email" value={email ?? ""} onChange={(e) => setEmail(e.target.value)} onInvalid={validateEmail} required />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='button-or-processing'>
                                {processing ?
                                    <ProcessingCircle></ProcessingCircle> :
                                    <button type="submit">{t("login-next")}</button>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
            {statusLabel ? ( <div className='important-message'>{t(statusLabel)}</div> ) : ""}
        </div>
    );
}

export default EmailRegistration;
